<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="`판매 상세보기`" :width="800">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">공지사항</label>
							<div class="col-sm-10">
								<span class="form-control" style="height: auto;">{{ modalData.notice }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">주문서 URL</label>
							<div class="col-sm-8">
								<span class="form-control" style="overflow-wrap: break-word; height: auto;">{{ modalData.originUrl }}</span>
							</div>
							<div class="col-sm-2"><button type="button" class="btn btn-secondary" @click="onClickAccountClipboard(modalData.originUrl)">URL 복사</button></div>
						</div>
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">입력 아이디</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.createUserId }}</span>
							</div>
							<label for="selResult" class="col-sm-2 col-form-label">입력일</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onFormatDateYmd(modalData.createDatetime) }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">수정 아이디</label>
							<div class="col-sm-4">
								<span class="form-control">{{ modalData.modifyUserId }}</span>
							</div>
							<label for="selResult" class="col-sm-2 col-form-label">수정일</label>
							<div class="col-sm-4">
								<span class="form-control">{{ onFormatDateYmd(modalData.modifyDatetime) }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">상품</label>
							<div class="col-sm-10">
								<table class="table table-bordered no-mrg text-center">
                                    <colgroup>
                                        <col />
                                        <col width="80" />
                                        <col width="80" />
                                        <!--
                                        <col width="80" />
                                        <col width="80" />
                                        <col width="80"/>
                                        -->
                                    </colgroup>
									<thead>
										<tr>
											<th>상품명</th>
											<th>단가</th>
                                            <th>주문단위</th>
                                            <!--
											<th>배송방법</th>
											<th>배송구분</th>
											<th>배송비</th>
                                            -->
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in ProductData" :key="index">
											<td class="text-left">{{ item.name }}</td>
											<!--<td>{{ onNumberFormat(item.quantity) }}</td>-->
											<td>{{ onNumberFormat(item.unitPrice) }}원</td>
                                            <td>{{ item.unitQuantity?item.unitQuantity:1 }}</td>
                                            <!--
											<td>{{ item.deliveryType }}</td>
											<td>{{ item.deliveryPayType }}</td>
											<td>{{ onNumberFormat(item.deliveryFee) }}</td>
                                            -->
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'
import { Clipboard } from "v-clipboard";

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalData   : [],
            isUpdate    : false,
			ProductData : []
        }
    },
    methods: {
        onShow(payload) {
			this.modalData = JSON.parse(JSON.stringify(payload))
            this.$refs.modal.onShow()
            this.getModalData()
        },

        getModalData() {
			this.$connect(
                `/api/sale/${this.modalData.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.modalData = bodyData.list;
				this.modalData.originUrl = this.modalData.originUrl?this.modalData.originUrl:`https://goldcarriage.kr/SHOP?token=${this.modalData.token}`
				//console.log("modalData:", this.modalData)
				this.onGetProduct()
            })
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentDetail', {
                isUpdate: this.isUpdate
            })
        },

		onGetProduct(){
            this.$connect(
				`/api/productInSale/get/${this.modalData.seq}`,
                'GET'
            )
            .then(bodyData => {
				this.ProductData = bodyData.list
            })
            .catch(error => {
                console.log("error", error);
            })
        },

		/**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        },

        /**
         * 글 복사
         *
         * @method onClickAccountClipboard
         * @param {string} url 주소 문자열
         */
        onClickAccountClipboard(url){
            Clipboard.copy(url);
        },

        openInNewTab(url) {
            window.open(url, '_blank');
        }
    },
}
</script>