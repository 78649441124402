<template>
    <div>
        <div class="boxwrap">
            <div class="searchwrap">
                <form>
                    <div class="form-row align-items-center">
                        
                        <label class="col-form-label">판매상태</label>
                        <!--
                        <select-box
                            ref="selectBox"
                            :selectBoxs="[
                                { name: '상태', value: 'STATUS' },
                            ]"
                            :default-select-box-value="searchType"
                            @onChangeSearchBox="setSelectBox"
                            :key="viewKey + 1"
                        ></select-box>
                        -->
                        <div class="col-auto ssb-search w-25 text-left" v-if="searchType === 'STATUS'">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    name="osRadioInline1"
                                    class="custom-control-input"
                                    id="osRadioInline0"
                                    value=""
                                    v-model="searchText"
                                    checked
                                />
                                <label class="custom-control-label" for="osRadioInline0">전체</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    name="osRadioInline1"
                                    class="custom-control-input"
                                    id="osRadioInline1"
                                    value="ON"
                                    v-model="searchText"
                                    checked
                                />
                                <label class="custom-control-label" for="osRadioInline1">운영</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    name="osRadioInline1"
                                    class="custom-control-input"
                                    id="osRadioInline2"
                                    value="OFF"
                                    v-model="searchText"
                                />
                                <label class="custom-control-label" for="osRadioInline2">종료</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12 text-center">
                        <button type="button" class="btn btn-primary btn-search" @click="onClickSearch">검색</button>&nbsp;
                        <button type="button" class="btn btn-secondary btn-search" @click="onClickClear">전체검색</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="title">
                <div class="option-btn mrg-left-10">
					<select class="form-control" v-model="tableListRow" @change="onChangeListRow">
                        <option value="10">10개씩 보기</option>
						<option value="20">20개씩 보기</option>
						<option value="40">40개씩 보기</option>
						<option value="80">80개씩 보기</option>
						<option value="100">100개씩 보기</option>
					</select>
				</div>
                <div class="option-btn mrg-left-10">
                    <a href="javascript:void(0)" class="btn btn-primary btn-sm" @click="onClickModalContentSave">판매 등록</a>
                </div>
                <h3 class="text-left">목록 (총
                    <strong class="point">{{ totalCount }}</strong>건)
                </h3>
            </div>
            <table class="table table-bordered">
                <thead class="thead-contact">
                    <tr>
                        <th scope="col">일련번호</th>
                        <th scope="col">상품명</th>
                        <th scope="col">단가 / 주문단위</th>
                        <!--<th scope="col" style="width:260px">배송</th>-->
                        <th scope="col">URL</th>
                        <th scope="col">판매자 이름</th>
                        <th scope="col">상태변경</th>
                        <th scope="col">관리</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, index) in tableList">
                        <tr :key="index" class="link">
                            <td @click="onClickModalContentDetail(item)">{{ item.seq }}</td>
                            <td class="no-pdd" style="text-wrap: nowrap;" @click="onClickModalContentDetail(item)">
                                <template v-if="item.productList && item.productList.length > 0">
                                    <table class="table inner-table no-mrg">
                                        <tr v-for="(productItem, productIndex) in item.productList" :key="productIndex">
                                            <td class="text-left" style="text-wrap: nowrap;">{{ productItem.name }}</td>
                                        </tr>
                                    </table>
                                </template>
                            </td>
                            <td class="no-pdd" @click="onClickModalContentDetail(item)">
                                <template v-if="item.productList && item.productList.length > 0">
                                    <table class="table inner-table no-mrg">
                                        <tr v-for="(productItem, productIndex) in item.productList" :key="productIndex">
                                            <td>{{ onNumberFormat(productItem.unitPrice) }}원</td>
                                            <td>{{ productItem.unitQuantity?productItem.unitQuantity:1 }}</td>
                                        </tr>
                                    </table>
                                </template>
                            </td>
                            <td>
								<!--
                                <div 
                                style="
                                display: flex;
                                flex-direction: row;
                                justify-content: space-evenly;"
                                @click="onClickAccountClipboard(item.shortUrl)"
                                >
                                    <span>짧은URL : </span>
                                    <div style="
                                        display: inline-block; 
                                        width: 150px; 
                                        overflow: hidden; 
                                        text-overflow: ellipsis;
                                    "
                                    >{{ item.shortUrl }}</div> 
                                    <span><i class="fa fa-clipboard" style="font-size: 18px;"></i> &nbsp;[URL 복사]</span>
                                </div>--><!--@click="openInNewTab(item.shortUrl)"-->
                                <div 
                                style="
                                display: flex;
                                flex-direction: row;
                                justify-content: space-evenly;
                                margin-top: 10px;"
                                @click="onClickAccountClipboard(
									item.originUrl?item.originUrl:`https://goldcarriage.kr/SHOP?token=${item.token}`
								)"
                                >
                                    <div style="
                                        display: inline-block; 
                                        width: 200px; 
                                        overflow: hidden; 
                                        text-overflow: ellipsis;
										word-break: break-word;
                                    "
                                    >{{ item.originUrl?item.originUrl:`https://goldcarriage.kr/SHOP?token=${item.token}` }}</div> 
                                    <span><i class="fa fa-clipboard" style="font-size: 18px;"></i> &nbsp;[URL 복사]</span>
                                </div>
                                <!--@click="openInNewTab(item.originUrl)"-->
                            </td>
							<td @click="onClickModalContentDetail(item)">{{ item.ownerUserName }}</td>				
                            <td>
                                <select v-model="item.status" class="form-control" @change="onChangeStatus(item)">
                                    <option value="ON">운영</option>
                                    <option value="OFF">종료</option>
                                </select>
                            </td>
                            <td>
                                <button type="button" class="btn btn-secondary btn-xs" @click="onClickModalContentSave(item)">수정</button>
                                &nbsp;<button type="button" class="btn btn-secondary btn-xs" @click="onClickModalContentDelete(item)">삭제</button></td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <paging-nations 
			ref="pagingNations" 
			:total-content-count="totalCount" 
			:row-per-page=parseInt(tableListRow)
			v-on:onClickPage="getTMPageNum" 
			:key="pagingViewKey" 
			v-show="totalCount !== 0"
			></paging-nations>
        </div>

		<!-- 상세보기 -->
        <modal-content-detail 
            ref="modalContentDetail" 
            @onCloseContentDetail="onCloseContentDetail"
		></modal-content-detail>

        <!--등록/수정-->
		<modal-content-save 
            ref="modalContentSave" 
            @onCloseContentSave="onCloseContentSave"
		></modal-content-save>

        <!--삭제-->
		<modal-content-delete 
            ref="modalContentDelete" 
            @onCloseContentDelete="onCloseContentDelete"
		></modal-content-delete>

    </div>
</template>
  
<script>
import { tableMixin } from '../../table-mixin'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'
import { mapState } from 'vuex'
import axios from 'axios'
import { Clipboard } from "v-clipboard";
//import SelectBox from '../../components/SelectBox'

import Pagingnations from '../../components/Pagingnations'
import MContentDetail from '../../modal/Sales/MContentDetail'
import MContentSave from '../../modal/Sales/MContentSave'
import MContentDelete from '../../modal/Sales/MContentDelete'

export default {
    name: 'salesList',
    mixins: [tableMixin],
    components: {
        //'select-box'             : SelectBox,
        'paging-nations'         : Pagingnations,
		'modal-content-detail'   : MContentDetail,
        'modal-content-save'     : MContentSave,
        'modal-content-delete'   : MContentDelete,
    },
    data() {
        return {
            searchType   : 'STATUS',
            searchText   : '',
            totalCount   : 0,
            tableList    : [],
            tableListRow : 10,
            userInfo     : {}
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
    },
    mounted() {
        //목록 LOAD
        this.getTMData();
    },
    computed: {
        ...mapState(['domainUrl'])
    },
    methods: {
        /**
         * 데이터를 가져오는 함수
         *
         * @method getTMData
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
        async getTMData() {
            this.tableList = [];
            this.totalCount = 0;

            const channel = this.$store.getters.getChannel
            const param = {
                "channel"          : channel,
                "startDate"        : "",
                "endDate"          : "",
                "searchType"       : this.searchText?this.searchType:"",
                "searchText"       : this.searchText,
                "orderingParam"    : "",
                "orderingAscending": ""
            }
            //console.log(param)
            try {
                const bodyData = await this.$connect(`/api/sale/list/${this.searchData.currentPage}/${this.tableListRow}`, 'POST', param);
                this.tableList = bodyData.list.content;
                console.log("this.tableList:", this.tableList)
                this.totalCount = bodyData.totalCount;
                await this.onGetProduct(); // 상품 데이터 로드 후에 테이블을 다시 렌더링
            } catch (error) {
                console.log("error", error);
            }
        },

		/**
         * 상세보기 모달 함수
         *
         * @method onModalContentDetail
         * @param {Object} item 선택한 아이템 정보
         */
		 onClickModalContentDetail(item) {
            this.$refs.modalContentDetail.onShow(item)
        },

        /**
         * 상세보기 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
        onCloseContentDetail(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

		/**
         * 상세보기 모달 함수
         *
         * @method onClickModalContentSave
         * @param {Object} item 선택한 아이템 정보
         */
		 onClickModalContentSave(item) {
            this.$refs.modalContentSave.onShow(item)
        },

        /**
         * 상세보기 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
        onCloseContentSave(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 상태 변경 함수
         *
         * @method onChangeSalesState
         * @param {Object} item 선택한 아이템 정보
         */
		onChangeSalesState(item){

		},

        /**
         * 삭제 모달 함수
         *
         * @method onClickModalContentDelete
         * @param {Object} item 선택한 아이템 정보
         */
        onClickModalContentDelete(item){
            this.$refs.modalContentDelete.onShow(item)
        },

        /**
         * 삭제 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentSave
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
         onCloseContentDelete(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 목록 보여지는 ROW 수 변경 함수
         *
         * @method onChangeListRow
         * @param 
         */
         onChangeListRow(){
            this.getTMData()
        },

        /**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        },

        async onGetProduct() {
            for (const item of this.tableList) {
                try {
                    const response = await axios.get(`${this.domainUrl}/api/productInSale/get/${item.seq}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${this.userInfo.userToken}`
                        }
                    });
                    item.productList = response.data.body.list;
                    this.$set(item, 'productList', response.data.body.list)
                } catch (error) {
                    //
                }
            }

            // 데이터가 모두 로드된 후에 화면이 다시 렌더링되도록 강제 갱신
            this.$forceUpdate();
        },

        /**
         * 글 복사
         *
         * @method onClickAccountClipboard
         * @param {string} url 주소 문자열
         */
        onClickAccountClipboard(url){
            Clipboard.copy(url);
            alert("URL이 복사되었습니다.")
        },

        /**
         * 셀렉트 박스 값을 설정하는 함수
         *
         * @method setSelectBox
         * @param {Object} payload 셀렉트 박스 관련 데이터를 담은 객체
         */
         setSelectBox(payload){
            this.searchType  = payload.selectValue
            this.searchText = ''
        },

        /**
         * 검색 함수
         *
         * @method onClickSearch
         */
         onClickSearch() {
            this.getTMData();
            this.$refs.pagingNations.currentPage = 1;
        },

        /**
         * 초기화(전체검색) 함수
         *
         * @method onClickClear
         */
        onClickClear() {
            this.searchType  = 'STATUS'
            this.searchText = ''
            this.$refs.selectBox.setSelectedValue(this.searchType);

            this.totalCount = 0
            this.tableList = []
            this.getTMData()
        },

        onChangeStatus(item){
            if(confirm("판매상태를 변경하시겠습니까?")){
                this.$connect(
                    `/api/sale`,
                    'POST', item
                )
                .then(bodyData => {
                    this.getTMData()
                })
            }
        },
        openInNewTab(url) {
            window.open(url, '_blank');
        }

    }
}
</script>
<style scoped>
.inner-table {
    border: none;
    height: 100%;
}
.inner-table td {
    border-top: none;
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
}
.inner-table td:first-child {
    border-left: none;
}
.inner-table td:last-child {
    border-right: none;
}
.inner-table tr:nth-child(n+2) td {
    border-top: 1px dashed #dee2e6;
}
</style>